import each from 'lodash/each';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import keys from 'lodash/keys';
import update from 'immutability-helper';
import moment from 'moment';
import { trimPrefix } from '../../common/helpers';
import {
	getAppState,
	getValidDomainData,
	sortByCol
} from '../util';
import {
	C_BOTTOM_LEFT,
	C_BOTTOM_RIGHT,
	C_TOP_LEFT,
	C_TOP_RIGHT,
	CL_OVERVIEW,
	CL_REPORT,
	COL_AREA_ID,
	CR_BAD,
	CR_AVERAGE,
	CR_GOOD,
	CS_EXIST,
	CS_PIN,
	CS_REPORT,
	CS_SPECIAL,
	CT_EXPANDABLE_TABLE,
	CT_GENERAL,
	CT_GENERAL_BAR,
	CT_GENERAL_TABLE,
	CT_GENERAL_TOTAL,
	CT_GENERAL_TOTAL_TIME,
	CT_LEADERBOARD,
	CT_CONTACTSTOPLIST,
	CT_MULTI_TOTAL,
	CT_DOUGHNUT,
	CT_MATCHES,
	CT_ORG_BAR,
	CT_SUMMARY_HORZ_BAR,
	I18N_NO_AGGREGATE,
	LEADERBOARD_STR,
	CONTACTS_TOPLIST_STR,
	LT_CLOSED,
	LT_HANDLING_AND_CLOSED,
	LT_HANDLING_TIME,
	MAP_COL_ID_TO_RDDCDT,
	MAP_DATA_TYPE_TO_AGG_DRILLDOWN,
	MAP_NO_AGG_DRILLDOWN,
	OC_ACTIVE_AGENTS,
	SR_CAP_UTIL_CHAT,
	SR_CHAT_SESSIONS,
	SR_CHAT_RATING,
	SR_FACEBOOK_RATING,
	OC_LEADERBOARD,
	OC_ORGANISATION,
	OL_LEFT_PANE,
	OL_MID_PANE,
	OL_RIGHT_PANE,
	OC_LIMITS,
	ORG_STR,
	RATING_WORD,
	RDDCDT_NORMALIZED,
	RDDCDT_UNKNOWN,
	RDDCDT_ORGANIZATION,
	RDDT_NORMAL,
	RDDT_UNSUPPORTED,
	RT_TABLE,
	SR_CLOSED,
	SR_LEADERBOARD,
	SR_SATISFY_METER,
	SR_ORG_OVERVIEW,
	SR_ACTIVE_AGENT,
	SR_NUMBER_OF_CONTACTS,
	SR_CLOSED_AND_ANSWERED,
	STATISTICS_TITLE,
	UNKNOWN_PHOTO_LINK,
	VIEW_REPORT,
	SCHEDULED_REPORT,
	emptyObject,
	emptyArray,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP,
	SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION,
	SR_INBOUND_AND_OUTBOUND_CALLS,
	LIVE_REPORT_EMAIL,
	LIVE_REPORT_CHAT,
	LIVE_REPORT_VOICE,
	LIVE_REPORT_OVERVIEW,
	SYSTEM_REPORTS,
	CUSTOM_REPORTS
} from '../../common/v5/constants';
import {
	DEF_REPORT_PARAM
	, PC_NEW_CHART_LAYOUT
} from '../../common/v5/statisticConstants';
import { I, L } from '../../common/v5/config';
import { isChartGrid } from '../../common/v5/helpers';
import {
	keyAdminPinReports,
	keyAdminReportDrilldown,
	keyAdminReports,
	keyGroups,
	keyTimeformat,
	keyTimeTimespan,
	keyTimespanFormats,
	keyTimeTimezones,
	keyCollaboratorList
} from '../constants/keys';
import {
	getAxesY,
	genChartData,
	numberToTime,
	getKeyValue
} from '../../reactcomponents/common';
import {
	chartIdentifier,
	getChartUpdater,
	getPinReportParam,
	normalizeOverviewLayout,
	stateName
} from '../reducers/statistics';
import {
	convertToNestedData
	, convertSystemReportLeaderboardDataToLeaderboardData
	, convertSystemReportNumberOfContactsToContactsToplistData
	, makeNotReadinessSelector
	, noSelector
	, KEY_PREFIX
} from './common';
import { constants, featureSelector } from './server';
import {
	makeArraySelector,
	statisticsAreaListSelector,
	getNormalizedAgents,
	onlyActiveAgentsSelector,
	organizationOverviewAreasSelector,
	queueEmailList,
	activeEmailList,
	getAllActiveAgents,
	emailGroupQueue,
	incomingFetchWIP,
	activeEmailsFetchWIP,
	activeAgentsFetchWIP
} from './workflow';
import {
	queueChatList,
	liveActiveChatsData,
	chatAreasInfo,
	getAgentAreaChats,
	queuesDataWIP,
	liveActiveChatsWIP
} from './chat';
import {
	sipAgentQueue,
	sipActiveCalls,
	sipGroupQueue,
	sipGetActiveCallsWip,
	sipGetAllAgentsWip,
	sipGetSipNumberInUse
} from './call';

export const getStatisticsRoot = store => getAppState(store, 'statistics');

export const getStatisticsState = (
	store
	, key
) => getStatisticsRoot(store)[stateName(key)];

const getView = state => getStatisticsRoot(state).view;
const getViewDrilldown = state => getView(state).drilldown;
const getDrilldownParameters = state => getViewDrilldown(state).parameters;
const getCharts = state => getStatisticsRoot(state).charts;
const createChartSelector = field => state => getCharts(state)[field];
const getChartsLayout = createChartSelector("layout");
const getChartsById = createChartSelector("byId");
const grid = createChartSelector("grid");
const getAllExistingReports = state => getStatisticsRoot(state).reports;
const chartMap = createChartSelector("chartMap");
const getLayouts = state => getStatisticsRoot(state).layouts;
const createOverviewChartLayout = func => state => func(state)[CL_OVERVIEW];
const overviewChartLayout = createOverviewChartLayout(getChartsLayout);
const createReportChartLayout = func => state => func(state)[CL_REPORT];

export const overviewChartMapMemo = createOverviewChartLayout(chartMap);

export const overviewGridMemo = createOverviewChartLayout(grid);

const overviewServerLayout = createOverviewChartLayout(getLayouts);

export const reportChartLayout = createReportChartLayout(getChartsLayout);

export const getAllReports = state => getAllExistingReports(state).data;

const defChart = {type: CT_GENERAL};

function appendChartType(data, id) {
	if (!data) {
		return defChart
	}
	return update(data, {type: {$set: CT_MATCHES[id]}});
}

function getChartID(state, props) {
	return props.id;
}

function getChartType(state, props) {
	return getChartID(state, props).chart;
}

function getChartIdentifier(state, { layout, position, idx, index }) {
	return chartIdentifier(layout, position, idx, index);
}

export const chartIdAndInfo = (state, props) => {
	const id = getChartIdentifier(state, props);
	return {id, info: getChartUpdater(id).get(getCharts(state))};
};

function getChartInfo(state, props) {
	return chartIdAndInfo(state, props).info;
}

function getChartDataByIdentifier(state, props) {
	const chartInfo = getChartInfo(state, props);
	if (!chartInfo) {
		return false;
	}
	return chartInfo.data;
}

function getReports(state) {
	return getStatisticsState(state, keyAdminReports);
}

export function getSystemReportsByName(state) {
	return getReports(state).systemReportsByName;
}

export function getReportsById(state) {
	const { byId } = getReports(state);
	if (!byId) {
		return emptyObject;
	}
	return byId;
}

function systemReportAttribute(chartId, chartSourceType, reportsByName) {
	if (chartSourceType !== CS_REPORT) {
		// currently attribute only carry by normal reports.
		return;
	}
	const report = reportsByName[chartId];
	if (!report || !report.attribute) {
		return;
	}
	return report.attribute;
}

function getSystemReportAttribute(state, props) {
	const { id, type } = getChartID(state, props);
	return systemReportAttribute(id, type, getSystemReportsByName(state));
}

function getTimeEvaluation(timeInSeconds) {
	if (timeInSeconds > 3600) {
		return CR_BAD;
	} else if (timeInSeconds > 600) {
		return CR_AVERAGE;
	}
	return CR_GOOD;
}

function extraInfoBaseOnFirstKey(data) {
	let total, type, tooltip, rating;
	const key0 = data.header.keys[0];
	if (key0) {
		const key0id = key0.id;
		type = CT_MATCHES[key0id.substring(KEY_PREFIX.length)];
		if (typeof type === 'undefined') {
			type = CT_GENERAL;
		}
		const dataTotal = data.total;
		if (dataTotal) {
			const totalData = dataTotal[key0id];
			if (typeof totalData !== 'undefined') {
				total = totalData;
				if (type === CT_GENERAL_TOTAL_TIME) {
					const unformat = dataTotal[key0id + '_unformatted'];
					if (typeof unformat !== 'undefined') {
						rating = getTimeEvaluation(unformat);
						tooltip = RATING_WORD[rating];
					}
				}
			}
		}
	} else {
		type = CT_GENERAL;
	}
	return {total, type, tooltip, rating};
}

function getTotalData(dataTotal, key) {
	const totalData = dataTotal[key];
	if (typeof totalData !== 'undefined') {
		return totalData;
	}
	return 0;
}

const corneredTotalData = (name, dataTotal, key) => ({
	total: getTotalData(dataTotal, key)
	// , name // TODO: can not really fix in the name here
});

function isOtherTotalKeys(otherTotalKeys, key) {
	if (!otherTotalKeys || otherTotalKeys.length <= 0) {
		return false;
	}
	let found;
	$.each(otherTotalKeys, (i, oneKey) => {
		if (key === KEY_PREFIX+oneKey) {
			found = true;
			return false;
		}
	});
	return !!found;
}

const cornersOrder = [C_BOTTOM_RIGHT, C_BOTTOM_LEFT, C_TOP_RIGHT, C_TOP_LEFT];

const testCorneredTotals = {
	"top-left": {name: "Active chat agent", total: 100}
	, "top-right": {name: "Top right", total: 99}
	, "bottom-right": {name: "Active chat agent", total: 88}
	, "bottom-left": {name: "All", total: 55}
};

function extraMultiTotal(data, mainTotalKey, otherTotalKeys) {
	const dataTotal = data.total;
	let total = 0, currentCornerOrder = 0, corneredTotals;
	if (dataTotal) {
		const { keys } = data.header;
		if (keys.length > 0) {
			$.each(keys, (i, { id, name }) => {
				if (id === KEY_PREFIX+mainTotalKey) {
					total = getTotalData(dataTotal, id);
				} else if (isOtherTotalKeys(otherTotalKeys, id)) {
					const corner = cornersOrder[currentCornerOrder];
					if (corneredTotals) {
						corneredTotals[corner] = corneredTotalData(
							name
							, dataTotal
							, id
						);
					} else {
						corneredTotals = {[corner]: corneredTotalData(
							name
							, dataTotal
							, id
						)};
					}
					currentCornerOrder++;
				}
			});
		}
	}
	return {corneredTotals, title: data.header.title, total};
}

function createMultiTotalProcessor(mainTotalKey, otherTotalKeys) {
	return (chartData, { chatEnabled }) => ({
		data: emptyObject
		, extra: extraMultiTotal(chartData, mainTotalKey, otherTotalKeys)
		, type: chatEnabled ? CT_MULTI_TOTAL : CT_GENERAL_TOTAL
	});
}

function genOrgChartData(chartData) {
	const { header } = chartData;
	let orgData = [];
	getAxesY(chartData, 0).map((y, Yi) => {
		const filtered = chartData.data.filter(row => y.id === row["group0"]);
		let total = 0
			, totalOpen = 0
			, expired = 0
			, warning = 0
			, closed = 0
			, rest = 0
			;
		$.each(filtered, (i, row) => {
			total += getKeyValue(row, "key_overview_total_errands_unformatted")
			totalOpen += getKeyValue(row, "key_overview_new_errands_unformatted")
				+ getKeyValue(row, "key_overview_my_errands_unformatted")
				+ getKeyValue(row, "key_overview_folders_unformatted");
			expired += getKeyValue(row, "key_overview_expired_unformatted");
			warning += getKeyValue(row, "key_overview_warnings_unformatted");
		});
		if (totalOpen) {
			expired = Math.round(expired/totalOpen*100);
			warning = Math.round(warning/totalOpen*100);
			closed = Math.round((total-totalOpen)/total*100);
			rest = 100 - (expired + warning);
		}
		orgData.push({
			datasets: [expired, warning, closed, rest, totalOpen]
			, orgId: y.id
			, title: y.name
			, total: total
		});

	});
	return {data: orgData, title: header.title};
}

// exclude report key from specific system report
const MAP_SYSTEM_REPORTS_EXCLUDEKEYS = {
	[SR_ORG_OVERVIEW]: [
		"key_overview_newest_errands"
		, "key_overview_oldest_errands"
		, "key_overview_total_errands"
	],
	[SR_ACTIVE_AGENT]: [
		"key_expired_in_my_errands"
		, "key_lastseen_from_now"
		, "key_overview_my_errands"
		, "key_timestamp_lastseen"
		, "key_warning_in_my_errands"
	]
}

// any specific system report handling code should NOT process here.
function processStandardReportData(
	chartData
	, { id, type: sourceType, chart }
	, useExpandableTable
	, showSubTableTotal
	, reportsByName
) {
	// console.log('dbg: report:', id, ' type:', sourceType);
	if (chart === CT_EXPANDABLE_TABLE
		|| chart === CT_GENERAL_TABLE
		&& useExpandableTable) {
		const [
				tableData
				, extraTableData
			] = convertToNestedData(chartData, showSubTableTotal)
			, attribute = systemReportAttribute(id, sourceType, reportsByName)
			;
		let extra;
		if (!attribute || !attribute.sorted) {
			extra = extraTableData;
		} else {
			extra = update(extraTableData, {$merge: {sorted: attribute.sorted}});
		}
		return {data: tableData, extra, type: chart};
	} else if (chart === CT_GENERAL_TABLE) {
		// let the table process the data itself
		return {data: chartData, extra: emptyObject, type: chart};
	}
	const excludeKeys = MAP_SYSTEM_REPORTS_EXCLUDEKEYS[id];
	const { data, title } = genChartData(id, chartData, excludeKeys, chart)
		, {
			type: chartType
			, total
			, tooltip
			, rating
		} = extraInfoBaseOnFirstKey(chartData)
		;
	let type
		;
	if (typeof chart === 'undefined') {
		type = chartType;
	} else {
		type = chart;
	}
	return {data, extra: {title, total, tooltip, rating}, type};
}

export const chartIdString = (layout, position, idx, index) => {
	const chartId = chartIdentifier(layout, position, idx, index);
	if (typeof chartId === "object") {
		const { layout, idx } = chartId
		return layout + ":" + idx;
	}
	return chartId;
}

function getOriginChartIdentifier(state, { origin }) {
	const { layout, position, idx, index } = origin;
	return chartIdString(layout, position, idx, index);
}

function getExpandableTable(state, props) {
	return props.useExpandable;
}

function getShowSubTableTotal(state, props) {
	return props.showSubTableTotal;
}

function processSystemReportLeaderboardData(chartData, { agents, reportsByName }) {
	const data = convertSystemReportLeaderboardDataToLeaderboardData(
			chartData
			, agents
		)
		, attribute = systemReportAttribute(SR_LEADERBOARD, CS_REPORT, reportsByName)
		, extra = {title: LEADERBOARD_STR}
		;
	if (attribute && attribute.sorted) {
		extra.sorted = attribute.sorted;
	}
	return {data, extra, type: CT_LEADERBOARD};
}

function processSystemReportContactsToplistData(chartData, { clients }) {
	const data = convertSystemReportNumberOfContactsToContactsToplistData(chartData, clients)
		, extra = {title: CONTACTS_TOPLIST_STR}
		;
	return {data, extra, type: CT_CONTACTSTOPLIST};
}

function processSystemReportSatisfyMeter(chartData, { agents }) {
	const data = []
		, labels = ['1', '2', '3', '4', '5']
		, { total } = chartData
		, title = chartData.header.title
		, sum = total["key_chose_satisfaction_meter_answer1_unformatted"] +
				total["key_chose_satisfaction_meter_answer2_unformatted"] +
				total["key_chose_satisfaction_meter_answer3_unformatted"] +
				total["key_chose_satisfaction_meter_answer4_unformatted"] +
				total["key_chose_satisfaction_meter_answer5_unformatted"]
		;
	if (sum) {
		const getPercent = which => Math.round(total['key_chose_satisfaction_meter_answer'+which+'_unformatted']/sum*1000) / 10;
		let sumPercent = 0
		, percent
		;
		for (var i = 0; i < labels.length-1; i++) {
			percent = getPercent(labels[i]);
			sumPercent += percent;
			data.push(percent);
		}
		data.push(100-sumPercent);
	} else {
		for (let i=0; i<5; i++) {
			data.push(20);
		}
	}
	return {
		data
		, extra: {title, total, labels, colorOffset:2}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportClosedAndAnswered(chartData, { agents }) {
	const data = []
		, labels = []
		, { total } = chartData
		, title = chartData.header.title
		;
	if (total.key_errand_closure_status_answered && chartData.data.length>0) {
		const getPercent = which => Math.round(chartData.data[which].key_errand_closure_status_answered/total.key_errand_closure_status_answered*1000) / 10;
		let sumPercent = 0
		, percent
		;
		if (chartData.data.length>1){
			for (var i = 0; i < chartData.data.length-1; i++) {
				percent = getPercent(i);
				sumPercent += percent;
				labels.push(chartData.data[i].group0_name);
				data.push(percent);
			}
		}
		percent = 100-sumPercent;
		labels.push(chartData.data[chartData.data.length-1].group0_name);
		data.push(percent);
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportAnsweredAndHandlingTime(chartData, { agents }) {
	const data = []
		, labels = []
		, { total } = chartData
		, title = chartData.header.title
		;
	if (total.key_errand_closure_status_answered && chartData.data.length>0) {
		const getPercent = which => Math.round(chartData.data[which].key_errand_closure_status_answered/total.key_errand_closure_status_answered*1000) / 10;
		const getKeyName = index => chartData.header.keys[index].name;
		const getGroupName = index => chartData.header.groups[index].name;
		let sumPercent = 0
		, percent
		, group_name = getGroupName(0)
		, group_value
		, key_name_answered = getKeyName(0)
		, key_name_process_time = getKeyName(1)
		, key_value_answered
		, key_value_process_time
		, textLabel
		;
		if (chartData.data.length>1){
			for (var i = 0; i < chartData.data.length-1; i++) {
				percent = getPercent(i);
				sumPercent += percent;
				group_value = chartData.data[i].group0_name;
				key_value_answered = chartData.data[i].key_errand_closure_status_answered;
				key_value_process_time = chartData.data[i].key_average_errand_process_time;
				textLabel = group_name + ": " + group_value + "," + key_name_answered + ": " +
					key_value_answered + "," + key_name_process_time + ": " + key_value_process_time;
				labels.push(textLabel);
				data.push(percent);
			}
		}
		percent = 100-sumPercent;
		group_value = chartData.data[chartData.data.length-1].group0_name;
		key_value_answered = chartData.data[chartData.data.length-1].key_errand_closure_status_answered;
		key_value_process_time = chartData.data[chartData.data.length-1].key_average_errand_process_time;
		textLabel = group_name + ": " + group_value + "," + key_name_answered + ": " +
			key_value_answered + "," + key_name_process_time + ": " + key_value_process_time;
		labels.push(textLabel);
		data.push(percent);
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportVoiceErrandAnsweredAndCallTime(chartData, { agents }) {
	const data = []
		, labels = []
		, { total } = chartData
		, title = chartData.header.title
		;
	if (total.key_voice_closure_status_answered && chartData.data.length>0) {
		const getPercent = which => Math.round(chartData.data[which].key_voice_closure_status_answered/total.key_voice_closure_status_answered*1000) / 10;
		const getKeyName = index => chartData.header.keys[index].name;
		const getGroupName = index => chartData.header.groups[index].name;
		let sumPercent = 0
		, percent
		, group_name = getGroupName(0)
		, group_value
		, key_name_answered = getKeyName(0)
		, key_name_process_time = getKeyName(1)
		, key_value_answered
		, key_value_process_time
		, textLabel
		;
		if (chartData.data.length>1){
			for (var i = 0; i < chartData.data.length-1; i++) {
				percent = getPercent(i);
				sumPercent += percent;
				group_value = chartData.data[i].group0_name;
				key_value_answered = chartData.data[i].key_voice_closure_status_answered;
				key_value_process_time = chartData.data[i].key_average_call_time;
				textLabel = group_name + ": " + group_value + "," + key_name_answered + ": " +
					key_value_answered + "," + key_name_process_time + ": " + key_value_process_time;
				labels.push(textLabel);
				data.push(percent);
			}
		}
		percent = 100-sumPercent;
		group_value = chartData.data[chartData.data.length-1].group0_name;
		key_value_answered = chartData.data[chartData.data.length-1].key_voice_closure_status_answered;
		key_value_process_time = chartData.data[chartData.data.length-1].key_average_call_time;
		textLabel = group_name + ": " + group_value + "," + key_name_answered + ": " +
			key_value_answered + "," + key_name_process_time + ": " + key_value_process_time;
		labels.push(textLabel);
		data.push(percent);
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportClosedVoiceErrand(chartData, { agents }) {
	const data = []
		, labels = []
		, { total } = chartData
		, title = chartData.header.title
		;
	if (total.key_closed_voice_errand && chartData.data.length>0) {
		const getPercent = which => Math.round(chartData.data[which].key_closed_voice_errand/total.key_closed_voice_errand*1000) / 10;
		const getKeyName = index => chartData.header.keys[index].name;
		const getGroupName = index => chartData.header.groups[index].name;
		let sumPercent = 0
		, percent
		, group_name = getGroupName(0)
		, group_value
		, key_name_closed = getKeyName(0)
		, key_name_process_time = getKeyName(1)
		, key_value_closed
		, key_value_process_time
		, textLabel
		;
		if (chartData.data.length>1){
			for (var i = 0; i < chartData.data.length-1; i++) {
				percent = getPercent(i);
				sumPercent += percent;
				group_value = chartData.data[i].group0_name;
				key_value_closed = chartData.data[i].key_closed_voice_errand;
				key_value_process_time = chartData.data[i].key_average_errand_process_time;
				textLabel = group_name + ": " + group_value + "," + key_name_closed + ": " +
					key_value_closed + "," + key_name_process_time + ": " + key_value_process_time;
				labels.push(textLabel);
				data.push(percent);
			}
		}
		percent = 100-sumPercent;
		group_value = chartData.data[chartData.data.length-1].group0_name;
		key_value_closed = chartData.data[chartData.data.length-1].key_closed_voice_errand;
		key_value_process_time = chartData.data[chartData.data.length-1].key_average_errand_process_time;
		textLabel = group_name + ": " + group_value + "," + key_name_closed + ": " +
			key_value_closed + "," + key_name_process_time + ": " + key_value_process_time;
		labels.push(textLabel);
		data.push(percent);
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportfbRating(chartData, { agents }) {
	const data = []
		, labels = ['YES', 'NO']
		, { total } = chartData
		, title = chartData.header.title
		, sum = total["key_facebook_ratings_yes_unformatted"] +
				total["key_facebook_ratings_no_unformatted"]
		;

	if (sum) {
		const getPercent = which => Math.round(total['key_facebook_ratings_'+which+'_unformatted']/sum*1000) / 10;
		let sumPercent = 0
		, percent
		;
		for (var i = 0; i < labels.length-1; i++) {
			percent = getPercent(labels[i].toLowerCase());
			sumPercent += percent;
			data.push(percent);
		}
		data.push(100-sumPercent);
	} else {
		data.push(50); // No
		data.push(50); // Yes
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportChatRating(chartData, { agents }) {
	const data = []
		, labels = ['YES', 'NO']
		, { total } = chartData
		, title = chartData.header.title
		, sum = total["key_chat_rating_yes_unformatted"] +
				total["key_chat_rating_no_unformatted"]
		;

	if (sum) {
		const getPercent = which => Math.round(total['key_chat_rating_'+which+'_unformatted']/sum*1000) / 10;
		let sumPercent = 0
		, percent
		;
		for (var i = 0; i < labels.length-1; i++) {
			percent = getPercent(labels[i].toLowerCase());
			sumPercent += percent;
			data.push(percent);
		}
		data.push(100-sumPercent);
	} else {
		data.push(50); // No
		data.push(50); // Yes
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSystemReportInboundOutboundCalls(chartData, { agents }) {
	const data = []
		, labels = ['Inbound', 'Outbound']
		, { total } = chartData
		, title = chartData.header.title
		, sum = total["key_inbound_calls"] +
				total["key_outbound_calls"]
		;
	if (sum) {
		let percent = Math.round(total['key_inbound_calls_unformatted']/sum*1000) / 10;
		data.push(percent);
		data.push(100-percent);
	} else {
		data.push(50);
		data.push(50);
	}
	return {
		data
		, extra: {title, total, labels}
		, type: CT_DOUGHNUT
	};
}

function processSROrgOverviewBase(chartData, { agents }, processor, type) {
	const extraResult = extraInfoBaseOnFirstKey(chartData)
		, chartResult = processor(chartData)
		, data = chartResult.data
		, tooltip = extraResult.tooltip
		, rating = extraResult.rating
		;
	let total, title;
	if (type === CT_GENERAL_TOTAL) {
		const { total: dataTotal } = chartData;
		title = I('All Errands');
		total = dataTotal["key_overview_new_errands_unformatted"]
			+ dataTotal["key_overview_my_errands_unformatted"]
			+ dataTotal["key_overview_folders_unformatted"];
	} else {
		title = chartResult.title;
		total = extraResult.total;
		if (typeof type === "undefined") {
			type = extraResult.type;
		}
	}
	return {
		data
		, extra: {title, total, tooltip, rating}
		, type
	};
}

const createProcessSROrgOverview = type => (chartData, extra) =>
	processSROrgOverviewBase(
		chartData
		, extra
		, chartData => genChartData(
			SR_ORG_OVERVIEW
			, chartData
			, MAP_SYSTEM_REPORTS_EXCLUDEKEYS[SR_ORG_OVERVIEW]
		)
		, type
	);

const processSROrgOverviewOrgBar = (chartData, extra) =>
	processSROrgOverviewBase(
		chartData
		, extra
		, genOrgChartData
	);

function processTotalChartSessions(chartData) {
	const { data, title: linkTitle } = genChartData(SR_CHAT_SESSIONS, chartData)
		, { total, tooltip, rating } = extraInfoBaseOnFirstKey(chartData)
		;
	return {
		data
		, extra: {title: I('Chats in queue'), linkTitle, total, tooltip, rating}
		, type: CT_GENERAL_TOTAL
	};
}

// Handle special case for processing report data for chart to use. The map is
// arranged as chart-source, id of the chart-source and chart type. The value
// must be a function type. Support undefined 'chart' processor. Also supports
// 'default' at 'id' branch which will be used when there isn't any 'chart'
// match (rarely needed unless table data process change).
const SPECIAL_PROCESS_REPORT_DATA = {
	[CS_REPORT]: {
		[SR_CHAT_SESSIONS]: {
			[CT_GENERAL_TOTAL]: processTotalChartSessions
		}
		, [SR_LEADERBOARD]: {
			[CT_LEADERBOARD]: processSystemReportLeaderboardData
			, [CT_GENERAL]: undefined // NOTE: for future guide line layout.
		}
		, [SR_NUMBER_OF_CONTACTS]: {
			[CT_CONTACTSTOPLIST]: processSystemReportContactsToplistData
		}
		, [SR_SATISFY_METER]: {
			undefined: processSystemReportSatisfyMeter
			, [CT_DOUGHNUT]: processSystemReportSatisfyMeter
		}
		, [SR_FACEBOOK_RATING]: {
			undefined: processSystemReportfbRating
			, [CT_DOUGHNUT]: processSystemReportfbRating
		}
		, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
			undefined: processSystemReportAnsweredAndHandlingTime
			, [CT_DOUGHNUT]: processSystemReportAnsweredAndHandlingTime
		}
		, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
			undefined: processSystemReportAnsweredAndHandlingTime
			, [CT_DOUGHNUT]: processSystemReportAnsweredAndHandlingTime
		}
		, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
			undefined: processSystemReportClosedVoiceErrand
			, [CT_DOUGHNUT]: processSystemReportClosedVoiceErrand
		}
		, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
			undefined: processSystemReportClosedVoiceErrand
			, [CT_DOUGHNUT]: processSystemReportClosedVoiceErrand
		}
		, [SR_CLOSED_AND_ANSWERED]: {
			undefined: processSystemReportClosedAndAnswered
			, [CT_DOUGHNUT]: processSystemReportClosedAndAnswered
		}
		, [SR_ORG_OVERVIEW]: {
			undefined: processSROrgOverviewOrgBar
			, [CT_ORG_BAR]: processSROrgOverviewOrgBar
			, [CT_GENERAL]: createProcessSROrgOverview(CT_GENERAL)
			, [CT_GENERAL_BAR]: createProcessSROrgOverview(CT_GENERAL_BAR)
			, [CT_GENERAL_TOTAL]: createProcessSROrgOverview(CT_GENERAL_TOTAL)
		}
		, [SR_ACTIVE_AGENT]: {
			[CT_MULTI_TOTAL]: createMultiTotalProcessor(
				"active_agent"
				, ["active_chat_agents"]
			)
		}
		, [SR_CLOSED]: { // NOTE: for future guide line layout.
			undefined: undefined
			, default: undefined
			, [CT_GENERAL_TOTAL]: undefined
		}
		, [SR_CHAT_RATING]: {
			undefined: processSystemReportChatRating
			, [CT_DOUGHNUT]: processSystemReportChatRating
		}
		, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: {
			undefined: processSystemReportVoiceErrandAnsweredAndCallTime
			, [CT_DOUGHNUT]: processSystemReportVoiceErrandAnsweredAndCallTime
		}
		, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: {
			undefined: processSystemReportVoiceErrandAnsweredAndCallTime
			, [CT_DOUGHNUT]: processSystemReportVoiceErrandAnsweredAndCallTime
		}
		, [SR_INBOUND_AND_OUTBOUND_CALLS]: {
			undefined: processSystemReportInboundOutboundCalls
			, [CT_DOUGHNUT]: processSystemReportInboundOutboundCalls
		}
	}
	, [CS_SPECIAL]: { // NOTE: for future guide line layout.
		[OC_ACTIVE_AGENTS]: {
			[CT_GENERAL_TOTAL]: undefined
		}
		, [OC_LEADERBOARD]: undefined
	}
};

function getSpecialReportDataProcessor({ type, id, chart }, reportsById) {
	if (SPECIAL_PROCESS_REPORT_DATA[type]) {
		if (type === CS_REPORT && typeof id === "number") {
			if (!reportsById) {
				return;
			}
			const report = reportsById[id];
			if (!report || !report.SystemReport) {
				return;
			}
			id = report.Name;
		}
		const reportDataProcessor = SPECIAL_PROCESS_REPORT_DATA[type][id];
		if (reportDataProcessor) {
			const processor = reportDataProcessor[chart];
			if (typeof processor === "function") {
				return processor;
			} else if (typeof reportDataProcessor.default === "function") {
				return reportDataProcessor.default;
			}
		}
	}
}

function getNormalizedClients(state) {
	const { byId } = state.domain.clientAvatar;
	if (!byId) {
		return emptyObject;
	}
	return byId;
}

export const featureChatEnabled = noSelector(featureSelector, ({ chat }) => !!chat);

const genReportDataSelector = createCachedSelector(
	featureChatEnabled
	, getChartDataByIdentifier
	, getChartID
	, getExpandableTable
	, getShowSubTableTotal
	, getSystemReportsByName
	, getReportsById
	, getNormalizedAgents
	, getNormalizedClients
	, (
		chatEnabled
		, chartData
		, chartID
		, useExpandableTable
		, showSubTableTotal
		, reportsByName
		, reportsById
		, agents
		, clients
	) => {
		if (!chartData) {
			return false;
		}
		const special = getSpecialReportDataProcessor(chartID, reportsById);
		if (special) {
			return special(
				chartData
				, {chatEnabled, reportsByName, agents, clients}
			);
		}
		return processStandardReportData(
			chartData
			, chartID
			, useExpandableTable
			, showSubTableTotal
			, reportsByName
		);
	}
)(
	getOriginChartIdentifier
);

// const mockLeaderboardData = [{
// 		id: 10,
// 		img: process.env.PATH_PREFIX+"img/profile/anders.png",
// 		name: "Anders Eriksson",
// 		handlingTime: "00:06:32",
// 		closedErrands: 344
// 	}, {
// 		id: 15,
// 		img: process.env.PATH_PREFIX+"img/profile/stina.png",
// 		name: "Lotta Carlsson",
// 		handlingTime: "00:35:32",
// 		closedErrands: 500
// 	}];

const leaderBoardTitle = {title: LEADERBOARD_STR};

function processLeaderboardData(data) {
	if (!data[LT_HANDLING_AND_CLOSED]) {
		return false;
	}
	// console.log('dbg: leaderboard should only appear once');
	const both = data[LT_HANDLING_AND_CLOSED].data;
	let newData = [];
	$.each(both, (i, v) => {
		let imgLink = UNKNOWN_PHOTO_LINK;
		if(v.agentavatar.length > 0){
			imgLink = v.agentavatar;
		}
		newData.push(update(v, {$merge: {
			img: imgLink,
			handlingTime: numberToTime(v.handling),
			closedErrands: v.closed
		}}));
	});
	return appendChartType({
		data: newData // fake data use mockLeaderboardData
		, extra: leaderBoardTitle
	}, OC_LEADERBOARD);
}

// TODO: useless
const leaderboardDataSelector = createCachedSelector(
	getChartDataByIdentifier
	, data => {
		if (!data) {
			return false;
		}
		return processLeaderboardData(data);
	}
)(
	getOriginChartIdentifier
);

// const org1 = {
// 		datasets: [20, 40, 25, 15]
// 		, title: "Recycle Department"
// 		, total: 1234
// 	}
// 	, org2 = {
// 		datasets: [20, 20, 25, 35]
// 		, title: "RnD Organization"
// 		, total: 2222
// 	}
// 	, mockOrgData = {
// 		data: [org1, org2]
// 		, extra: {title: 'Organisations'}
// 	}
// 	;
const orgTitle = {title: ORG_STR};

function processOrgData(orgData, chart) {
	// console.log('dbg: org should only appear once');
	let data = [], overallOpen = 0;
	$.each(orgData, (i,v) => {
		$.each(v, (title, w) => {
			let orgId, total = 0
				, totalOpen = 0
				, expired = 0
				, warning = 0
				, closed = 0
				, rest = 0
				;
			$.each(w, (j,x) => {
				orgId = x.OrgId;
				total += x.Total;
				totalOpen += x.Inbox + x.My + x.Folder;
				expired += x.Expired;
				warning += x.Warnings;
			});
			if (totalOpen) {
				expired = Math.round(expired/totalOpen*100);
				warning = Math.round(warning/totalOpen*100);
				closed = Math.round((total-totalOpen)/total*100);
				rest = 100 - (expired + warning);
			}
			data.push({
				datasets: [
					expired
					, warning
					, closed
					, rest
					, totalOpen
				]
				, orgId
				, title
				, total
			});
			overallOpen += totalOpen;
		});
	});
	if (chart !== 'undefined' && chart === CT_GENERAL_TOTAL) {
		return {
			data: emptyArray
			, extra: {title: I('All Errands'), total: overallOpen}
			, type: CT_GENERAL_TOTAL
		};
	}
	return appendChartType({data, extra: orgTitle}, OC_ORGANISATION);
}

// TODO: useless
const organisationsDataSelector = createCachedSelector(
	getChartDataByIdentifier
	, getChartType
	, (orgData, chart) => {
		if (!orgData) {
			return false;
		}
		return processOrgData(orgData, chart);
	}
)(
	getOriginChartIdentifier
);

// const sampleActiveAgents = {
// 	data: {
// 		list: [
// 			{
// 				acquiredErrand: 0
// 				, acquiredErrandArea: null
// 				, expiredErrand: 0
// 				, expiredErrandArea: null
// 				, id: 216
// 				, lastSeen: "2018-03-27 06:26"
// 				, lastSeenDistance: "132 d ago"
// 				, lastSeenHuman: "2018-03-27 06:26"
// 				, name: "testing50_qa"
// 				, style: {bg: "#FEE", italic: false}
// 				, bg: "#FEE"
// 				, italic: false
// 				, warningErrand: 0
// 				, warningErrandArea: null
// 			}
// 		]
// 		, totals: {
// 			AcquiredErrands: 1096
// 			, ExpiredErrands: 37
// 			, LastSeenDistance: ""
// 			, LastSeenHuman: "Total logged in: 12"
// 			, Name: "Total active agents: 50"
// 			, WarningErrands: 1
// 		}
// 	}
// 	, extra: {
// 		title: I('Active agents')
// 		, total: 23
// 	}
// };

function processActiveAgentsData(data) {
	// console.log('dbg: active agents should only appear once');
	return appendChartType({
		data
		, extra: {
			title: I('Active agents')
			, total: data.Totals.totalLoggedInAgents
		}
	}, OC_ACTIVE_AGENTS);
}

// TODO: useless
const activeAgentsSelector = createCachedSelector(
	getChartDataByIdentifier
	, data => {
		if (!data) {
			return false;
		}
		return processActiveAgentsData(data);
	}
)(
	getOriginChartIdentifier
);

// TODO: useless
// NOTE: all special overview report had been refactored into system report and
// so these special reports are not used anymore.
const chartDataLocator = {
	[OC_ACTIVE_AGENTS]: activeAgentsSelector
	, [OC_LEADERBOARD]: leaderboardDataSelector
	, [OC_ORGANISATION]: organisationsDataSelector
};

function getChartData(state, props) {
	const { id: resourceID } = props
		, { id, type } = resourceID
		;
	if (type === CS_SPECIAL) {
		const specialChartID = chartDataLocator[id];
		if (!specialChartID) {
			return;
		}
		return specialChartID(state, props);
	}
	return genReportDataSelector(state, props);
}

function getChartParam(state, props) {
	const chartInfo = getChartInfo(state, props);
	if (!chartInfo) {
		return false;
	}
	return chartInfo.param;
}

const overviewChartDataSelector = createCachedSelector(
	getChartData
	, getChartParam
	, (chartData, param) => {
		if (!chartData) {
			return false;
		} else if (!param) {
			return chartData;
		}
		return update(chartData, {$merge: {param}});
	}
)(
	getOriginChartIdentifier
);

export const makeChartDataSelector = () => createSelector(
	overviewChartDataSelector
	, data => {
		if (!data) {
			return;
		}
		return data.data;
	}
);

export const makeChartExtraSelector = () => createSelector(
	overviewChartDataSelector
	, data => {
		if (!data) {
			return;
		}
		return data.extra;
	}
);

export const makeChartParamSelector = () => createSelector(
	overviewChartDataSelector
	, data => {
		if (!data) {
			return;
		}
		return data.param;
	}
);

export const makeChartTypeSelector = () => createSelector(
	overviewChartDataSelector
	, data => {
		if (!data) {
			return;
		}
		return data.type;
	}
);

export const makeChartImageRequestedSelector = () => noSelector(
	getChartInfo
	, chartInfo => {
		if (!chartInfo) {
			return false;
		}
		return !!chartInfo.imageRequested;
	}
);

const chatOnlySystemReport = {
	[SR_CAP_UTIL_CHAT]: true
	, [SR_CHAT_SESSIONS]: true
};

function isChatOnlySystemReport({ id, type }) {
	if (type === CS_REPORT && chatOnlySystemReport[id]) {
		return true;
	}
	return false;
}

function getChartWidgetOrder(state) {
	return overviewChartLayout(state)[OL_LEFT_PANE];
}

function removeChatOnlySystemReport(charts) {
	let updated = [];
	charts.forEach(chart => !isChatOnlySystemReport(chart) ? updated.push(chart) : false)
	if (updated.length === charts.length) {
		return charts;
	}
	return updated;
}

function orderLimit(order, limit, chatEnabled) {
	if (!order || !order.length) {
		return emptyArray;
	}
	if (order.length > limit) {
		order = order.slice(0, limit);
	}
	if (!chatEnabled) {
		order = removeChatOnlySystemReport(order);
	}
	return order;
}

export const overviewWidgetListSelector = createSelector(
	getChartWidgetOrder
	, featureChatEnabled
	, (order, chatEnabled) => orderLimit(
		order
		, OC_LIMITS[OL_LEFT_PANE]
		, chatEnabled
	)
);

function getMidPanelOrder(state) {
	return overviewChartLayout(state)[OL_MID_PANE];
}

export const overviewMidPanelListSelector = createSelector(
	getMidPanelOrder
	, featureChatEnabled
	, (order, chatEnabled) => orderLimit(
		order
		, OC_LIMITS[OL_MID_PANE]
		, chatEnabled
	)
);

function getRightPanelOrder(state) {
	return overviewChartLayout(state)[OL_RIGHT_PANE];
}

export const overviewRightPanelListSelector = createSelector(
	getRightPanelOrder
	, featureChatEnabled
	, (order, chatEnabled) => orderLimit(
		order
		, OC_LIMITS[OL_RIGHT_PANE]
		, chatEnabled
	)
);

function updateWithUpdater(updatee, updater) {
	if (!updater) {
		return updatee
	}
	return update(updatee, updater);
}

function originalChartLayoutId(existChart, { chart, param }) {
	let updater;
	if (typeof chart !== "undefined") {
		updater = {chart: {$set: chart}};
	}
	if (typeof param !== "undefined") {
		if (!updater) {
			updater = {param: {$set: param}};
		} else {
			updater.param = {$set: param};
		}
	}
	return updateWithUpdater(existChart, updater);
}

// firstValidChart MUST immutable.
function updateFirstValidChart(firstValidChart, existChart) {
	const { chart, param } = firstValidChart;
	let updater;
	if (typeof chart === "undefined"
		&& typeof existChart.chart !== "undefined") {
		updater = {chart: {$set: existChart.chart}};
	}
	if (typeof param === "undefined"
		&& typeof existChart.param !== "undefined") {
		if (!updater) {
			updater = {param: {$set: existChart.param}};
		} else {
			updater.param = {$set: existChart.param}
		}
	}
	return updateWithUpdater(firstValidChart, updater);
}

// keep this pure function.
const chartLayoutInfo = (chartLayouts, { layout, position, idx, index }) => {
	let chartLayout;
	if (typeof chartLayouts === "function") {
		chartLayout = chartLayouts(layout)[layout];
	} else {
		chartLayout = chartLayouts[layout];
	}
	if (!chartLayout) {
		return;
	}
	if (isChartGrid(layout)) {
		return chartLayout[idx];
	}
	if (!chartLayout[position]) {
		return;
	}
	return chartLayout[position][index];
};

// chartLayout, firstValidChart and resourceID MUST immutable.
function resolveTargetChart(
	chartLayout
	, firstValidChart
	, mapTrack
	, resourceID
	, last
) {
	let { layout, position, idx, index } = resourceID;
	const chartID = chartIdString(layout, position, idx, index);
	if (mapTrack[chartID]) {
		return false;
	}
	mapTrack[chartID] = true; // track cyclic reference and quit
	if (typeof layout === 'undefined') {
		layout = CL_OVERVIEW;
	}
	let existChart = chartLayoutInfo(chartLayout, {layout, position, idx, index});
	if (!existChart) {
		return false;
	}
	if (last.layout === layout) {
		last.position = position;
		last.idx = idx;
		last.index = index;
	}
	const { id, type } = existChart;
	if (type === CS_EXIST) {
		return resolveTargetChart(
			chartLayout
			, updateFirstValidChart(firstValidChart, existChart)
			, mapTrack
			, id
			, last
		);
	}
	existChart = originalChartLayoutId(existChart, firstValidChart);
	if (last.layout !== layout) {
		layout = last.layout;
		position = last.position;
		idx = last.idx;
		index = last.index;
	}
	return {id: existChart, layout, position, idx, index};
}

function resolveExistChart(chartLayouts, id, original) {
	if (!id || id.type !== CS_EXIST) {
		return false;
	}
	return resolveTargetChart(chartLayouts, id, {}, id.id, original);
}

// this MUST be pure function.
function findExistChart(chartLayouts, id, layout, idx) {
	return resolveExistChart(chartLayouts, id, {layout, idx});
}

export const findOverviewExistChart = (
	chartMap
	, chartLayoutId
) => {
	if (!chartLayoutId || !chartLayoutId.id === "undefined") {
		return;
	}
	const target = findExistChart(chartMap, chartLayoutId, CL_OVERVIEW, '');
	if (!target) {
		return;
	}
	return target.id;
};

// chartId: {
//  id: string | { layout: string, idx: string }
//  , type: number, chart: number
// }
const updatePinParam = (pin, chartId) => {
	if (!pin || chartId.type !== CS_PIN) {
		return;
	}
	let param = getPinReportParam(pin[chartId.id]);
	if (chartId.param) {
		param = update(param, { $merge: chartId.param });
	}
	return update(chartId, { param: { $set: param } });
};

export const findChart = (chartData, pin, chartId, layout, idx) => {
	let chart = resolveExistChart(chartData, chartId, {layout, idx});
	if (chart) {
		chartId = chart.id;
	}
	chart = updatePinParam(pin, chartId);
	if (chart) {
		return chart;
	}
	return chartId;
};

export const findOverviewChartOnly = (chartData, pin, chartId) => findChart(
	chartData
	, pin
	, chartId
	, CL_OVERVIEW
	, ""
);

export function getFinalTargetChart(
	chartLayouts
	, id
	, pin
	, layout
	, position
	, idx
	, index
) {
	let chart = resolveExistChart(
		chartLayouts
		, id
		, {layout, position, idx, index}
	);
	if (!chart) {
		chart = emptyObject;
	}
	// TODO: passing id here probably wrong because do not take into account chart
	// exist pointing to pin report.
	const pinChartId = updatePinParam(pin, id);
	if (!pinChartId) {
		return chart;
	}
	return update(chart, { $merge: { id: pinChartId } });
}

export function getPinReportsById(state) {
	return getStatisticsState(state, keyAdminPinReports).byId;
}

function getOriginLayout(state, { layout }) {
	return layout;
}

// This is special selector as it returns a function instead of normally object
// which should be fine as selector just a memoized function.
export const chartsLayoutMemo = createSelector(
	getChartsLayout
	, chartMap
	, (oldLayout, gridChartMap) => layout => {
		if (isChartGrid(layout)) {
			return gridChartMap;
		}
		return oldLayout;
	}
);

export const normalizeOverviewLayoutMemo = createSelector(
	chartsLayoutMemo
	, chartLayoutSelector => normalizeOverviewLayout(
		PC_NEW_CHART_LAYOUT
		, chartLayoutSelector(CL_OVERVIEW)[CL_OVERVIEW]
	)
);

function getOriginPosition(state, { position }) {
	return position;
}

function getOriginIdx(state, { idx }) {
	return idx;
}

function getOriginIndex(state, { index }) {
	return index;
}

export const chartLayoutInfoMemo = noSelector(
	chartsLayoutMemo
	, getOriginLayout
	, getOriginPosition
	, getOriginIdx
	, getOriginIndex
	, (chartLayouts, layout, position, idx, index) => chartLayoutInfo(
		chartLayouts
		, {layout, position, idx, index}
	)
);

export const makeChartSourceSelector = () => createSelector(
	chartsLayoutMemo
	, getChartID
	, getPinReportsById
	, getOriginLayout
	, getOriginPosition
	, getOriginIdx
	, getOriginIndex
	, (chartLayouts, id, pin, layout, position, idx, index) => update(
		getFinalTargetChart(
			chartLayouts
			, id
			, pin
			, layout
			, position
			, idx
			, index
		)
		, {origin: {$set: {layout, position, idx, index}}}
	)
);

const defaultReportParam = DEF_REPORT_PARAM;

function getReportChartByParamId(state, props) {
	if (!props.paramId) {
		return;
	}
	return getChartUpdater(props.paramId.id).get(getCharts(state));
}

function getReportChartCompareByParamId(state, props) {
	if (!props.compareParamId) {
		return;
	}
	return getChartUpdater(props.compareParamId.id).get(getCharts(state));
}

export const reportParametersSelector = createSelector(
	getReportChartByParamId
	, chart => {
		if (!chart || !chart.param) {
			return defaultReportParam;
		}
		return chart.param;
	}
);

export const reportCompareParametersSelector = createSelector(
	getReportChartCompareByParamId
	, chart => {
		if (!chart || !chart.param) {
			return defaultReportParam;
		}
		return chart.param;
	}
);

export const isLoadingReportDataSelector = createSelector(
	getReportChartByParamId
	, chart => {
		if (!chart || !chart.loading) {
			return false;
		}
		return chart.loading;
	}
);

function getFieldFromChartEndpointParam(chartData, field, defaultValue) {
	if (!chartData || !chartData.endpointParam) {
		return defaultValue;
	}
	return chartData.endpointParam[field];
}

export const reportIdSelector = createSelector(
	getReportChartByParamId
	, chart => getFieldFromChartEndpointParam(chart, "id", 0)
);

export const reportSourceTypeSelector = createSelector(
	getReportChartByParamId
	, chart => getFieldFromChartEndpointParam(chart, "type", CS_REPORT)
);

const defaultReportOption = {
	hasCustomAbsoluteTimeGroup: false,
	hasCustomTimeFormatGroup: false,
	hasKeyFigureThatFilterByChannel: false,
	hasKeyFigureThatFilterByUser: false,
	hasKeyFigureThatFilterByArea: false,
	doesSomethingWithTags: false,
	noDateTimeRange: false,
	hasAggregateFilter: false,
	hasKeyFigureThatFilterByCollaborator: false
};

const reportsGetters = [
		state => getSystemReportsByName(state)
		, state => getReportsById(state)
	]
	, REPORTS_BY_NAME = 0
	, REPORTS_BY_ID = 1
	;
export const reportDataFromReports = (
	id
	, chartSource
	, reportsGetterMap
	, state
) => {
	if (chartSource === CS_REPORT && typeof id === "string") {
		const reports = reportsGetterMap[REPORTS_BY_NAME](state);
		if (reports && reports[id]) {
			return reports[id];
		}
	}
	return reportsGetterMap[REPORTS_BY_ID](state)[id];
};

export const reportDataFromChartLayoutId = (
	state
	, { id, type }
) => reportDataFromReports(id, type, reportsGetters, state);

function getReportData(state) {
	// Do not use props.paramId, when it is undefined, reportOptionSelector will
	// return incorrect data even later props.paramId become valid again
	const paramId = getView(state).reportParamId;
	if (!paramId) {
		return;
	}
	return reportDataFromChartLayoutId(state, paramId.chart);
}

const getCurrentScheduledData = state => getStatisticsRoot(state).scheduleReport.createNew;
const getActiveScheduledReportID = state => getView(state).schedule.activeId;

export const optionsFromReportData = reportData => {
	if (!reportData) {
		return defaultReportOption
	}
	const {
		hasCustomAbsoluteTimeGroup
		, hasCustomTimeFormatGroup
		, hasKeyFigureThatFilterByChannel
		, hasKeyFigureThatFilterByUser
		, hasKeyFigureThatFilterByArea
		, doesSomethingWithTags
		, noDateTimeRange
		, hasAggregateFilter
		, hasKeyFigureThatFilterByCollaborator
	} = reportData;
	return {
		hasCustomAbsoluteTimeGroup
		, hasCustomTimeFormatGroup
		, hasKeyFigureThatFilterByChannel
		, hasKeyFigureThatFilterByUser
		, hasKeyFigureThatFilterByArea
		, doesSomethingWithTags
		, noDateTimeRange
		, hasAggregateFilter
		, hasKeyFigureThatFilterByCollaborator
	};
};

export const reportOptionsSelector = createSelector(
	getActiveView
	, getReports
	, getReportData
	, getActiveScheduledReportID
	, getCurrentScheduledData
	, (view, reports, report, scheduleId , scheduledData) => {
		let reportData = report, scheduleReportId = 0;
		if(view === SCHEDULED_REPORT){
			if(scheduleId > 0){
				if(scheduledData){
					reportData = reports.byId[scheduledData.reportId];
				}
			}else{
				return defaultReportOption;
			}
		}
		return optionsFromReportData(reportData);
	}
);

function getGroups(state) {
	return getStatisticsState(state, keyGroups).data;
}

export const groupListSelector = makeArraySelector(getGroups, 'groups');

function getTimeFormat(state) {
	return getStatisticsState(state, keyTimeformat).data;
}

export const timeFormatListSelector = makeArraySelector(getTimeFormat, 'timeformats');

function getTimeSpan(state) {
	return getStatisticsState(state, keyTimeTimespan).data;
}

function getTimeSpanItems(state) {
	return getStatisticsState(state, keyTimespanFormats).data;
}

export const timeSpanFormatListSelector = makeArraySelector(getTimeSpan, 'timespan');

export const timeSpanItemsListSelector = makeArraySelector(getTimeSpanItems, 'items');

function getTimeZone(state) {
	return getStatisticsState(state, keyTimeTimezones).data;
}

export const timeZoneListSelector = makeArraySelector(getTimeZone, 'timeZones');

function getActiveView(state) {
	return getView(state).active;
}

export const activeViewMemo = getActiveView;

function getActiveViewReportID(state) {
	return getView(state).activeId;
}

function getActiveViewReportTitle(state) {
	return getView(state).title;
}

export function getCurrentReportChartId(state) {
	const { reportParamId } = getView(state);
	if (!reportParamId || !reportParamId.id) {
		return;
	}
	return reportParamId.id;
}

const createActiveViewReportFieldGetter = (fieldName, notFoundValue) => state => {
	const reports = getReports(state);
	if (!reports || !reports.byId) {
		return notFoundValue;
	}
	const report = reports.byId[getActiveViewReportID(state)];
	if (!report || !report[fieldName]) {
		return notFoundValue;
	}
	return report[fieldName];
};

const getActiveViewReportName = createActiveViewReportFieldGetter("Name", "");

function getActiveViewReportParameter(state) {
	const paramId = getView(state).reportParamId;
	if (!paramId || !paramId.id) {
		return defaultReportParam;
	}
	const data = getChartUpdater(paramId.id).get(getCharts(state));
	if (!data || !data.param) {
		return defaultReportParam;
	}
	return data.param;
}

export const sortedStatisticsReportSelector = createSelector(
	getReports,
	(reports) => {
		if (!reports || !reports.data) {
			return emptyObject;
		}
		const systemReports = [], customReports = [];

        reports.data.forEach(obj => {
            if (obj.GroupBy === SYSTEM_REPORTS) {
                systemReports.push(obj);
            } else {
                customReports.push(obj);
            }
        });
        return { systemReports, customReports }
	}
);

export const activeViewReportExportParameterSelector = createSelector(
	getActiveView
	, getActiveViewReportID
	, getActiveViewReportName
	, getActiveViewReportParameter
	, (view, reportId, reportName, param) => {
		if (view !== VIEW_REPORT || !reportId) {
			return defaultReportParam;
		}
		return update(param, {$merge: {
			reportId
			, reportName
			, reportType: RT_TABLE
		}});
	}
);

const getActiveViewReportI18nName = createActiveViewReportFieldGetter("i18nName", "");

export const headerTitleSelector = createSelector(
	getActiveView
	, getActiveViewReportI18nName
	, (view, i18nName) => {
		if (view !== VIEW_REPORT || !i18nName) {
			return STATISTICS_TITLE[view];
		}
		return i18nName;
	}
);

function getReportDrilldownData(state) {
	return getReportDrilldown(state).data;
}

function getReportDrilldown(state) {
	return getStatisticsState(state, keyAdminReportDrilldown);
}

const reportDrilldownEndpointNotReady = makeNotReadinessSelector(getReportDrilldown);

export const reportDrilldownNotReadySelector = createSelector(
	reportDrilldownEndpointNotReady
	, getReportDrilldownData
	, (notReady, data) => {
		if (notReady) {
			return notReady;
		} else if (!data) {
			return {reason: I("Invalid data")};
		} else if (data.resultType === RDDT_UNSUPPORTED) {
			return {
				reason: I("Requested Drill down report is not supported because {REASON}")
					.replace("{REASON}", data.error)
			};
		} else if (data.resultType === RDDT_NORMAL) {
			return false;
		}
		return {reason: I("Invalid data: {DATA}").replace("{DATA}", data)};
	}
);

const organisationsHeader = {
		Header: I("Organization")
		, accessor: COL_AREA_ID
		, drilldownDataType: RDDCDT_ORGANIZATION
	}
	, noAggregateSelected = {id: "", name: I18N_NO_AGGREGATE}
	, emptyResults = [[{data: emptyArray}], emptyArray]
	, emptyArrayOfArrays = [emptyArray, emptyArray]
	;
function convertOneResultHeaderIdNameToReactTableFormat(result, normData) {
	const { headers, dataType } = result;
	if (!headers || !headers.length) {
		return emptyArrayOfArrays;
	}
	const converted = []
		, aggregationOptions = [noAggregateSelected]
		;
	let aggregateColumn = MAP_DATA_TYPE_TO_AGG_DRILLDOWN[dataType];
	if (typeof aggregateColumn === "undefined") {
		aggregateColumn = headers[0].id;
	}
	$.each(headers, (i, v) => {
		const { id, name } = v;
		if (id === COL_AREA_ID) {
			converted.push(organisationsHeader);
		}
		// NOTE: this accessor id MUST be string from backend endpoint data as
		// it will be a reference for aggregation drilldown table to work.
		const oneHeader = {accessor: id}
			, dataType = MAP_COL_ID_TO_RDDCDT[id]
			;
		let drilldownDataType
			, header = L(name)
			;
		if (typeof dataType === "undefined") {
			if (normData && normData[id]) {
				if (normData[id].name) {
					header = L(normData[id].name);
				}
				drilldownDataType = RDDCDT_NORMALIZED;
			} else {
				drilldownDataType = RDDCDT_UNKNOWN;
			}
		} else {
			if (dataType.name) {
				header = dataType.name;
			}
			if (typeof dataType.arguments !== "undefined") {
				oneHeader.arguments = dataType.arguments;
			}
			if (typeof dataType.usePivot !== "undefined") {
				oneHeader.usePivot = dataType.usePivot;
			}
			drilldownDataType = dataType.type;
		}
		oneHeader.Header = header;
		oneHeader.drilldownDataType = drilldownDataType;
		converted.push(oneHeader);
		if (id !== aggregateColumn) {
			aggregationOptions.push(v);
		}
	});
	return [converted, {column: aggregateColumn, options: aggregationOptions}];
}

function convertResultsHeaderToReactTableFormat(results, normData) {
	if (!results || !results.length) {
		return emptyResults;
	}
	const aggregations = []
		, converted = []
		;
	$.each(results, (i, v) => {
		const [
				headers
				, aggregation
			] = convertOneResultHeaderIdNameToReactTableFormat(v, normData)
			, updated = update(v, {headers: {$set: headers}})
			;
		aggregations.push(aggregation);
		converted.push(updated);
	});
	return [converted, aggregations];
}

const reportDrilldownResultsData = noSelector(
	reportDrilldownNotReadySelector
	, getReportDrilldownData
	, (notReady, data) => {
		if (notReady || !data) {
			return;
		}
		return data;
	}
);

const processReportDrilldownResultsSelector = createSelector(
	reportDrilldownResultsData
	, data => {
		if (!data) {
			return [undefined, undefined];
		}
		const { results, normData } = data;
		return convertResultsHeaderToReactTableFormat(results, normData);
	}
);

export const reportDrilldownResultsSelector = noSelector(
	processReportDrilldownResultsSelector
	, ([ data ]) => data
);

export const reportDrilldownAggregationsSelector = noSelector(
	processReportDrilldownResultsSelector
	, ([ _, aggregations ]) => aggregations
);

export const reportDrilldownNormalizedDataSelector = createSelector(
	reportDrilldownResultsData
	, data => {
		if (!data) {
			return;
		}
		return data.normData;
	}
);

const systemReportsWithOverviewAreas = {
	[SR_ACTIVE_AGENT]: true
	, [SR_ORG_OVERVIEW]: true
	, [SR_SATISFY_METER]: true
};

function needOrgAreas(report) {
	if (report.SystemReport && systemReportsWithOverviewAreas[report.Name]) {
		return true;
	}
	return false;
}

const createStatisticAreasSelector = reportDataSelector => createSelector(
	reportDataSelector
	, statisticsAreaListSelector
	, organizationOverviewAreasSelector
	, (report, areas, orgAreas) => {
		if (!report) {
			return areas
		}
		// TODO: need rethink a bit on this overview areas (oa). oa is feature
		// dependent which historically exist for overview page which is removed
		// during 5.0. All overview reports should has one system report now.
		// But oa is different from system report areas because the later don't
		// depend on feature. Question will raise now what if custom report
		// create with keys from overview-system-report (osr) AND
		// normal-system-report (sr). What areas should be used in this case?
		// System report for overview e.g. organization, satisfaction meter.
		if (needOrgAreas(report)) {
			return {all: orgAreas, active: orgAreas};
		}
		return areas;
	}
);

const reportIdFromProps = (state, { reportId }) => reportId;

const createReportDataSelector = anyReportIdSelector => noSelector(
	anyReportIdSelector
	, getReports
	, (reportId, reports) => {
		if (!reports || !reports.byId) {
			return;
		}
		const report = reports.byId[reportId];
		if (!report || !report.Name) {
			return;
		}
		return report;
	}
);

export const statisticAreasFromReportId = createStatisticAreasSelector(
	createReportDataSelector(reportIdFromProps)
);

export const onlyActiveStatisticAreasSelector = noSelector(
	statisticAreasFromReportId
	, ({ active }) => active
);

export const reportAreasSelector = createStatisticAreasSelector(
	createReportDataSelector(getActiveViewReportID)
);

const getCurrentCreateReport = state => getStatisticsRoot(state).createReport.createNew;

function getAllReportKeys(state) {
	if(getStatisticsRoot(state).reportKeys.data){
		return getStatisticsRoot(state).reportKeys.data.keys;
	}
}

function getAllReportGroups(state) {
	if(getStatisticsRoot(state).reportGroups.data){
		return getStatisticsRoot(state).reportGroups.data.groups;
	}
}

const getCurrentConfigTime = state => getStatisticsRoot(state).configTime.createNew;

export const getSelectedTimeSpanInfo = createSelector(
	getCurrentConfigTime
	, timeSpanItemsListSelector
	, (configs, timespan) => {
		let obj = [];
		if(configs.selectedTimespan != ""){
			let selected = configs.selectedTimespan.toString().split(",");
			$.each(selected, (i,id) => {
				$.each(timespan, (n,c) => {
					if(id == c.Id){
						obj.push(c);
					}
				});
			});
		}
		return obj;
	}
)

export const getSelectedTimeSpanExample = createSelector(
	getCurrentConfigTime
	, timeSpanItemsListSelector
	, (configs, timespan) => {
		let obj = [];
		if(configs.selectedTimespan && configs.selectedTimespan != ""){
			let selected = configs.selectedTimespan.toString().split(",");
			$.each(selected, (i,v) => {
				let id = v;
				$.each(timespan, (n,c) => {
					if(id == c.Id){
						obj.push(getTimeFormatExample(c.Name));
					}
				});
			});
		}
		return obj;
	}
);

function getTimeFormatExample(name){
	let example = name.substr(name.indexOf(":") + 1);
	example = example.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
	return example;
}

export const getSelectedKeysName = createSelector(
	getCurrentCreateReport
	, getAllReportKeys
	, (cr, allKeys) => {
		let keyList = [], selectedKeys = cr.keys, keys = [];
		if(selectedKeys){
			keys = selectedKeys.split(",").map(v => parseInt(v, 10))
		}
		$.each(keys, (n, k) => {
			$.each(allKeys, (i, v) => {
				if(k == v.id){
					keyList.push(v.displayName);
				}
			});
		});
		return keyList;
	}
);

export const getSelectedGroupsName = createSelector(
	getCurrentCreateReport
	, getAllReportGroups
	, (cr, allGrps) => {
		let grpList = [], selectedGrps = cr.groups, groups = [];
		if(selectedGrps){
			groups = selectedGrps.split(",").map(v => parseInt(v, 10))
		}
		$.each(groups, (n, k) => {
			$.each(allGrps, (i, v) => {
				if(k == v.id){
					grpList.push(v.displayName);
				}
			});
		});
		return grpList;
	}
);

export const getSortedKeyNamePair = createSelector(
	getCurrentCreateReport
	, getAllReportKeys
	, (crKeys, allKeys) => {
		let sortedKeyList = [], selectedCRKeys = crKeys.keys , keys = [];
		if(selectedCRKeys) {
			keys = selectedCRKeys.split(",").map(v => parseInt(v, 10))
		}
		$.each(keys, function(i,v) {
			$.each(allKeys, function(n, k){
				if(v == k.id){
					sortedKeyList.push({
						Id: v,
						Name: k.displayName
					});
				}
			});
		});
		return sortedKeyList;
	}
);

export const getSortedGroupNamePair = createSelector(
	getSelectedGroupsName
	, getAllReportGroups
	, (gName, allGroup) => {
		let sortedGroupKeys = [];
		$.each(gName, function(i,v) {
			$.each(allGroup, function(n, k){
				if(v == k.displayName){
					sortedGroupKeys.push({
						Id: k.id,
						Name: v
					});
				}
			});
		});
		return sortedGroupKeys;
	}
);

export const getReportKeysCommonDependencies = createSelector(
	getCurrentCreateReport
	, getAllReportKeys
	, getAllReportGroups
	, (cr, allKeys, allGroups) => {
		let selectedKeys = cr.keys, keys = [];
		if(selectedKeys){
			keys = selectedKeys.split(",")
		}
		let groupsSet = [];
		$.each(keys, (i,v) => {
			$.each(allKeys, (n,k) => {
				if(v == k.id){
					groupsSet.push(k.dependencies);
				}
			});
		});
		if(groupsSet.length > 0){
			var groupSubset = groupsSet.shift().reduce(function(res, v) {
				if (res.indexOf(v) === -1 && groupsSet.every(function(a) {
					return a.indexOf(v) !== -1;
				})) res.push(v);
				return res;
			}, []);
		}
		let validGrps = [];
		$.each(allGroups, (i, v) => {
			$.each(groupSubset, (n, k) => {
				if(k == v.id){
					validGrps.push({id: v.id, displayName: v.displayName});
				}
			});
		});

		return validGrps;
	}
);

export const getShareReport = (state) => state.app.statistics.shareReport;

export const getSelectedAgents = createSelector(
	[
		onlyActiveAgentsSelector,
		getShareReport
	]
	, (acAgents, shareReport) => {
		const selected = shareReport.selectedAgent + ',';
		const arrSelected = selected.split(',');
		let selectedAgents = [];
		$.each(acAgents, (i, v) => {
			$.each(arrSelected, (n, k) => {
				if(Number(k) == v.Id){
					selectedAgents.push({id: v.Id, name: v.Name});
				}
			})
		});
		return selectedAgents;
	}
);

export const closeActionMessagesMemoize = createSelector(
	constants
	, ({ actions, notClosed }) => update(actions, {0: {$set: notClosed}})
);

export const allowAggregationDrilldown = noSelector(
	getDrilldownParameters
	, parameters => {
		if (!parameters) {
			return false;
		}
		return !MAP_NO_AGG_DRILLDOWN[trimPrefix(parameters.key, "key_")];
	}
);

export const getStartDay = offset => {
	if (!offset) {
		offset = "+0000";
	}
	return moment().utcOffset(offset).startOf('day').format('YYYY/MM/DD');
};

export const plainOverviewGridLayout = (grid, chartMap) => {
	let firstLayoutDone, firstLayout;
	const charts = [], layouts = {}, layoutMap = {};
	each(grid, (v, k) => {
		const layout = [];
		each(v, ({ h, i, w, x, y }, index) => {
			layout.push({h, w, x, y});
			if (!firstLayoutDone) {
				layoutMap[i] = index;
			}
		});
		if (!firstLayoutDone) {
			firstLayout = v;
			firstLayoutDone = true;
		}
		layouts[k] = layout;
	});
	each(firstLayout, ({ i }) => {
		let chart = chartMap[i];
		if (chart.type === CS_EXIST) {
			if (chart.id.layout !== CL_OVERVIEW) {
				console.log("unsupported cross page chart reference:", chart);
				return;
			}
			let index = layoutMap[chart.id.idx];
			if (typeof index === "undefined") {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: can't find target chart:", chart);
				}
				index = -1; // TODO: what better to handle this wrong reference chart?
			}
			chart = update(chart, {id: {$unset: ["idx"]}});
			chart = update(chart, {id: {index: {$set: index}}});
		}
		charts.push(chart);
	});
	return {charts, layouts};
};

const gridLayoutsEquality = (a, b) => {
	if (!a || !b) {
		return false;
	} else if (keys(a).length !== keys(b).length) {
		return false;
	}
	let confirmNotSame;
	each(a, (v, k) => {
		const bk = b[k];
		if (typeof v !== typeof bk) {
			confirmNotSame = true
			return false;
		} else if (v === bk) {
			return;
		} else if (v.length !== bk.length) {
			confirmNotSame = true
			return false;
		}
		each(v, (w, i) => {
			const bv = bk[i];
			if (typeof w !== typeof bv
				|| w.x !== bv.x
				|| w.y !== bv.y
				|| w.w !== bv.w
				|| w.h !== bv.h) {
				confirmNotSame = true
				return false;
			}
		});
		if (confirmNotSame) {
			return false;
		}
	});
	return !confirmNotSame;
};

// if both a and b have falsy value then they both consider same.
const truthfulSameTypeEquality = (type, a, b) => {
	if (typeof a === type && typeof b === type) {
		return a === b;
	} else if (!a && !b) {
		return true;
	}
	return false;
};

const truthfulObjectEquality = (a, b) => truthfulSameTypeEquality("object", a, b);

const chartParamEquality = (a, b) => {
	if (truthfulObjectEquality(a, b)) {
		return true;
	}
	let confirmNotSame;
	each(a, (v, k) => {
		const bv = b[k];
		if (typeof bv !== typeof v || bv !== v) {
			confirmNotSame = true;
			return false;
		}
	})
	return !confirmNotSame;
};

const truthfulNumberEquality = (a, b) => truthfulSameTypeEquality("number", a, b);

const existChartIdEquality = (a, b) => {
	if (a.layout !== b.layout) {
		return false;
	}
	return a.index !== b.index;
};

const chartsEquality = (a, b) => {
	let confirmNotSame;
	each(a, (v, i) => {
		if ((a.type !== b.type
			|| !truthfulNumberEquality(a.chart, b.chart)
			|| !chartParamEquality(a.param, b.param))
			|| (a.type === CS_EXIST && !existChartIdEquality(a.id, a.id))
			|| a.id !== b.id) {
			confirmNotSame = true
			return false;
		}
	});
	return !confirmNotSame;
};

const gridChartsEquality = (a, b) => {
	if (a === b) {
		return true;
	} else if (!a || !b) {
		return false;
	} else if (!gridLayoutsEquality(a.layouts, b.layouts)) {
		return false;
	}
	return chartsEquality(a.charts, b.charts)
};

const overviewGridChangedMemo = createSelector(
	overviewGridMemo
	, overviewChartMapMemo
	, overviewServerLayout
	, (grid, chartMap, server) => !gridChartsEquality(
		plainOverviewGridLayout(grid.layouts, chartMap)
		, server
	)
);

export const saveDisabledGridMemo = noSelector(
	overviewGridChangedMemo
	, changed => !changed
);

export const hideTitleAndLinkSelector = (state, props) => {
	if (props.hideTitleAndLink) {
		return true;
	} else {
		return !features["cention-reports"];
	}
};

const getCollaborators = state => getStatisticsState(state, keyCollaboratorList);

export const CollaboratorsSelector = createSelector(
	getCollaborators
	, ({data}) => {
		if (!data || !data.list || !data.list.length) {
			return emptyArray;
		}
		let list = [];
		$.each(data.list, (i, v) => {
			list.push({
				id: v.mailOriginId,
				name: v.name != "" ? v.name + "(" + v.emailAddress + ")" : v.emailAddress
			})
		});
		return list;
	}
);

//Live Report's Queue Data
export const liveReportQueueData = createSelector(
	activeViewMemo
	, queueChatList
	, queueEmailList
	, queuesDataWIP
	, incomingFetchWIP
	, (view, chatQ, emailsQ, chatWip, emailWip) => {
		let queueCols = [
			{header: "...", key: "channel", title: I("Channel"), type: "icon", width: '40', hide: (view === LIVE_REPORT_OVERVIEW ? false : true)},
			{header: view === LIVE_REPORT_VOICE ? I("Caller") : I("From"), key: "from"},
			{header: I("Status"), key: "status"}
		];
		let qData = [], wip = false;
		if(view === LIVE_REPORT_CHAT) {
			qData = chatQ;
		} else if (view === LIVE_REPORT_EMAIL) {
			qData = emailsQ;
		} else if(view === LIVE_REPORT_VOICE) {
			qData = emptyArray; //todo: for voice queued
		} else {
			qData = [...qData, ...emailsQ];
		}
		if(chatWip || emailWip) {
			wip = true;
		}
		return {cols: queueCols, data: qData, wip: wip};
	}
)

//Live Report's Conversations Data
export const liveReportInConversationData = createSelector(
	activeViewMemo
	, liveActiveChatsData
	, sipActiveCalls
	, activeEmailList
	, liveActiveChatsWIP
	, sipGetActiveCallsWip
	, activeEmailsFetchWIP
	, (view, chatActive, callsActive, emailsActive, chatWip, callsWip, emailsWip) => {
		let activeCols = [
			{header: view === LIVE_REPORT_VOICE ? I("Caller") : I("From"), key: "from"},
			{header: I("Group"), key: "group"},
			{header: I("Agent"), key: "agent"},
			{header: I("Status"), key: "status", type: "preIcon"}
		];
		let activeData = [], wip = false;
		if(view === LIVE_REPORT_CHAT) {
			activeData = chatActive;
		} else if (view === LIVE_REPORT_EMAIL) {
			activeData = emailsActive;
		} else if(view === LIVE_REPORT_VOICE) {
			activeData = callsActive;
			activeCols.push({header: I("Action"), key: "action", type: "snoop"});
		} else {
			activeData = [...chatActive, ...callsActive, ...emailsActive];
		}
		if(activeData.length > 0) {
			activeData = sortByCol(activeData, "status", "desc");
		}
		if(chatWip || callsWip || emailsWip) {
			wip = true;
		}
		return {cols: activeCols, data: activeData, wip: wip};
	}
)

//Live Report's Agent Queue Data
export const liveReportAgentsQueueData = createSelector(
	activeViewMemo
	, getAgentAreaChats
	, sipAgentQueue
	, getAllActiveAgents
	, sipGetAllAgentsWip
	, activeAgentsFetchWIP
	, liveActiveChatsWIP
	, (view, chatData, voiceData, activeAgents, sipAgentsWip, agentsWip, liveChatWip) => {
		let agentQCols = [
			{header: "...", key: "channel", title: I("Channel"), type: "icon",  width: '40', hide: (view === LIVE_REPORT_OVERVIEW ? false : true)},
			{header: I("Agent"), key: "agent"},
			{header: I("Status"), key: "status", type: "preIcon", onlineStatus: true},
			{header: I("Group"), key: "group", type: "list"},
			{header: view === LIVE_REPORT_VOICE ? I("Caller") : I("From"), key: "from", type: "list"}
		];
		let agentQData = [], wip = false;
		if(view === LIVE_REPORT_CHAT) {
			agentQData = chatData;
		} else if (view === LIVE_REPORT_EMAIL) {
			agentQData = activeAgents;
		} else if(view === LIVE_REPORT_VOICE) {
			agentQData = voiceData;
		} else {
			agentQData = [...chatData, ...voiceData, ...activeAgents];
		}
		if(agentQData.length > 0) {
			agentQData = sortByCol(agentQData, "status", "desc");
		}
		if( sipAgentsWip || agentsWip || liveChatWip) {
			wip = true;
		}
		return {cols: agentQCols, data: agentQData, wip: wip};
	}
)

//Live Report's Group Queue Data
export const liveReportGroupQueueData = createSelector(
	activeViewMemo
	, chatAreasInfo
	, sipGroupQueue
	, emailGroupQueue
	, liveActiveChatsWIP
	, activeEmailsFetchWIP
	, incomingFetchWIP
	, sipGetAllAgentsWip
	, sipGetActiveCallsWip
	, (view, chatData, voiceData, emailGrp, liveChatWip, activeEmailsWip, incomingWip, sipAgentWip, sipCallsWip) => {
		let groupQCols = [
			{header: "...", key: "channel", title: I("Channel"), type: "icon", width: '40', hide: (view === LIVE_REPORT_OVERVIEW ? false : true)},
			{header: I("Group"), key: "area"},
			{header: I("In queue"), key: "queue", type: "withText", text: I("in queue")},
			{header: I("In conversation"), key: "active", type: "withText", text: I("in conversation")},
			{header: I("Agents"), key: "agents", type: "withCustomText", onlineStatus: true}
		];
		let groupQData = [], wip = false;
		if(view === LIVE_REPORT_CHAT) {
			groupQData = chatData;
		} else if (view === LIVE_REPORT_EMAIL) {
			groupQData = emailGrp;
		} else if(view === LIVE_REPORT_VOICE) {
			groupQData = voiceData;
		} else {
			groupQData = [...chatData, ...voiceData, ...emailGrp];
		}
		if(liveChatWip || activeEmailsWip || incomingWip || sipAgentWip || sipCallsWip) {
			wip = true;
		}
		return {cols: groupQCols, data: groupQData, wip: wip};
	}
)

//Live Report's SIP number in use
export const liveReportSIPNumbInUsed = createSelector(
	activeViewMemo
	, sipGetSipNumberInUse
	, (view, voiceData) => {
		let agentSipsData = [];
		if(!voiceData) {
			return {cols: [], data: [], wip: false};
		}
		let sipNumberCols = [
			{header: I("Resources"), key: "sipId"},
			{header: I("Status"), key: "callStatus", type: "preIcon", onlineStatus: true},
			{header: I("Channel count"), key: "channelCount"}
		];
		if(voiceData && voiceData.data && voiceData.data.agents.length > 0) {
			agentSipsData = voiceData.data.agents;
		}
		return {cols: sipNumberCols, data: agentSipsData, wip: false};
	}
)
